import { LibraryAPI } from "api";
import { useFetchAngles } from "hooks/useFetchAngles";
import { useFetchAuthorities } from "hooks/useFetchAuthorities";
import { useFetchCategories } from "hooks/useFetchCategories";
import { useFetchCities } from "hooks/useFetchCities";
import { useFetchDistances } from "hooks/useFetchDistances";
import { useFetchMarkets } from "hooks/useFetchMarkets";
import { useFetchRegions } from "hooks/useFetchRegions";
import { useFetchSpaceAddresses } from "hooks/useFetchSpaceAddresses";
import { useFetchSpaceClasses } from "hooks/useFetchSpaceClasses";
import { useFetchSpaceScores } from "hooks/useFetchSpaceScores";
import { useFetchSpaceSize } from "hooks/useFetchSpaceSize";
import { useFetchSpaceTypes } from "hooks/useFetchSpaceTypes";
import { useFetchStatuses } from "hooks/useFetchStatuses";
import { useFetchVisibilities } from "hooks/useFetchVisibilities";
import useQuery from "hooks/useQuery";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  FloatingLabel,
  Form,
  FormControl,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ShippingAndWarrantyInfo = () => {
  const {
    register,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [volumeUOM, setVolumeUOM] = useState(watch("volumeUOM") || "cm");

  // Handle volumeUOM change for all selects
  const handleVolumeUOMChange = (e) => {
    const value = e.target.value;
    setVolumeUOM(value);
    setValue("volumeUOM", value); // update the form value
  };

  return (
    <>
      {/* <Card.Header as="h6" className="bg-body-tertiary mb-2">
        Shipping And Warranty
      </Card.Header> */}
      <Row className="gx-1 gy-1">
        <Col md="6">
          <InputGroup>
            <FormControl
              style={{ width: "80%" }}
              placeholder="Weight"
              title="Height"
              type="number"
              isInvalid={!!errors?.weight}
              {...register("weight")}
              aria-label="Text input with dropdown button"
            />
            {/* <Form.Select aria-label="Default select example">
              <option>Select</option>
              <option selected value="1">
                kg
              </option>
              <option value="2">lbs</option>
              <option value="3">g</option>
            </Form.Select> */}
            <Form.Select
              style={{
                appearance: "none", // Removes default arrow in most browsers
                MozAppearance: "none", // Firefox
                WebkitAppearance: "none", // Safari/Chrome
                background: "none", // Removes default background, if needed
              }}
              className=" px-1 fs-10"
              aria-label="Default select example"
              isInvalid={!!errors?.weightUOM}
              {...register("weightUOM")}
            >
              <option value="kg">kg</option>
              <option value="lbs">lbs</option>
              <option value="g">g</option>
            </Form.Select>
          </InputGroup>
        </Col>
        <Col md="6">
          <div className="d-flex align-items-center">
            <InputGroup>
              <FormControl
                style={{ width: "40%" }}
                placeholder="Length"
                title="Length"
                type="number"
                aria-label="Text input with dropdown button"
                isInvalid={!!errors?.length}
                {...register("length")}
              />
              <Form.Select
                style={{
                  appearance: "none", // Removes default arrow in most browsers
                  MozAppearance: "none", // Firefox
                  WebkitAppearance: "none", // Safari/Chrome
                  background: "none", // Removes default background, if needed
                }}
                className="px-1 fs-10"
                // aria-label="Default select example"
                isInvalid={!!errors?.volumeUOM}
                value={volumeUOM}
                onChange={handleVolumeUOMChange}
              >
                <option value="cm">cm</option>
                <option value="mm">mm</option>
                <option value="m">m</option>
              </Form.Select>
            </InputGroup>
            <span className="px-1">x</span>
            <InputGroup>
              <FormControl
                style={{ width: "40%" }}
                placeholder="Width"
                title="Width"
                type="number"
                aria-label="Text input with dropdown button"
                isInvalid={!!errors?.width}
                {...register("width")}
              />
              <Form.Select
                style={{
                  appearance: "none", // Removes default arrow in most browsers
                  MozAppearance: "none", // Firefox
                  WebkitAppearance: "none", // Safari/Chrome
                  background: "none", // Removes default background, if needed
                }}
                className="px-1 fs-10"
                aria-label="Default select example"
                isInvalid={!!errors?.volumeUOM}
                value={volumeUOM}
                onChange={handleVolumeUOMChange}
              >
                <option value="cm">cm</option>
                <option value="mm">mm</option>
                <option value="m">m</option>
              </Form.Select>
            </InputGroup>
            <span className="px-1">x</span>
            <InputGroup>
              <FormControl
                style={{ width: "40%" }}
                placeholder="Height"
                title="Height"
                type="number"
                aria-label="Text input with dropdown button"
                isInvalid={!!errors?.height}
                {...register("height")}
              />
              <Form.Select
                style={{
                  appearance: "none", // Removes default arrow in most browsers
                  MozAppearance: "none", // Firefox
                  WebkitAppearance: "none", // Safari/Chrome
                  background: "none", // Removes default background, if needed
                }}
                className="px-1 fs-10"
                aria-label="Default select example"
                isInvalid={!!errors?.volumeUOM}
                value={volumeUOM}
                onChange={handleVolumeUOMChange}
              >
                <option value="cm">cm</option>
                <option value="mm">mm</option>
                <option value="m">m</option>
              </Form.Select>
            </InputGroup>
          </div>
        </Col>

        <Col md="6">
          <Form.Select
            isInvalid={!!errors?.warranty}
            {...register("warranty")}
            aria-label="Default select example"
          >
            <option value="">Select Warranty</option>
            <option value="Seller Warranty">Seller Warranty</option>
            <option value="No Warranty">No Warranty</option>
          </Form.Select>
        </Col>
        <Col md="6">
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Type"
              title="Type"
              isInvalid={!!errors?.warrantyType}
              {...register("warrantyType")}
              // isInvalid={!!errors?.type}
              // {...register("type")}
            />
            <Form.Control.Feedback type="invalid">
              {/* {errors?.type?.message} */}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md="6">
          <Form.Select
            isInvalid={!!errors?.warrantyPeriod}
            {...register("warrantyPeriod")}
            aria-label="Default select example"
          >
            <option>Select Period</option>
            <option value="1 Month">1 Month</option>
            <option value="2 Month">2 Month</option>
            <option value="3 Month">3 Month</option>
            <option value="4 Month">4 Month</option>
            <option value="5 Month">5 Month</option>
            <option value="6 Month">6 Month</option>
            <option value="7 Month">7 Month</option>
            <option value="8 Month">8 Month</option>
            <option value="9 Month">9 Month</option>
            <option value="10 Month">10 Month</option>
            <option value="11 Month">11 Month</option>
            <option value="12 Month">12 Month</option>
          </Form.Select>
        </Col>
        <Col md="6">
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Policy"
              title="Policy"
              isInvalid={!!errors?.warrantyPolicy}
              {...register("warrantyPolicy")}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.warrantyPolicy?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default ShippingAndWarrantyInfo;
