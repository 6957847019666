import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  Image,
  Row,
  Table,
} from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FaImages } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  closestCorners,
} from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import ActionButton from "components/common/ActionButton";
import FalconLightBoxGallery from "components/common/FalconLightBoxGallery";
import SubtleBadge from "components/common/SubtleBadge";
import axios from "axios";
import LightBoxLatestExample from "components/common/LightBoxLatestExample";

const SortableItem = ({ id, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <tr ref={setNodeRef} style={style}>
      <td
        {...listeners}
        {...attributes}
        style={{
          cursor: "grab",
          textAlign: "center",
          width: "10px",
          paddingTop: "15px",
        }}
      >
        ☰
      </td>
      {children}
    </tr>
  );
};

const Dropzone = ({ onDrop, index }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      // "image/gif": [".gif"],
      // "image/webp": [".webp"],
    },
    multiple: true,
    onDrop: (acceptedFiles) => onDrop(acceptedFiles, index),
  });

  return (
    <div {...getRootProps({ className: "dropzone" })}>
      <input {...getInputProps()} />
      <div style={{ padding: "5px" }}>
        <FaImages size={25} />
      </div>
    </div>
  );
};

const VariantsBasicInformation = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [inputValues, setInputValues] = useState({});
  const [optionsList, setOptionsList] = useState({});
  const [imagesList, setImagesList] = useState({});
  const [imagesPreviewList, setImagesPreviewList] = useState({});
  const [serverImagesList, setServerImagesList] = useState({});

  const {
    register,
    setValue,
    control,
    getValues,
    formState: { errors },
  } = useFormContext();

  const {
    fields: options,
    append: appendOptions,
    remove: removeOptions,
    move,
  } = useFieldArray({
    control,
    name: "group",
  });

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  );

  const uploadImages = async (files) => {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("files", file); // 'files[]' is the key name on the backend
    });

    try {
      const response = await axios.post("/Catalogue/VariantFiles", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Assuming your backend returns a list of URLs
      return response?.data;
    } catch (error) {
      console.error("Error uploading images:", error);
      return [];
    }
  };

  // const handleDrop = useCallback(
  //   async (acceptedFiles, index) => {
  //     // console.log(acceptedFiles);
  //     const newImages = acceptedFiles.map((file) => URL.createObjectURL(file));

  //     setImagesPreviewList((prevImagesList) => {
  //       const updatedImagesPreview = {
  //         ...prevImagesList,
  //         [index]: [...(prevImagesList[index] || []), ...newImages],
  //       };

  //       // const updatedImages = {
  //       //   ...prevImagesList,
  //       //   [index]: [...(prevImagesList[index] || []), ...destinations],
  //       // };

  //       setValue(
  //         `options[${index}].optionImagesPreview`,
  //         updatedImagesPreview[index]
  //       );
  //       // setValue(`options[${index}].optionImages`, updatedImages[index]);
  //       return updatedImagesPreview;
  //     });
  //     // Upload images and get the URLs
  //     const response = await uploadImages(acceptedFiles);

  //     const destinations = response?.map((item) => item.destination);

  //     setImagesList((prevImagesList) => {
  //       const updatedImagesList = { ...prevImagesList };

  //       // Filter out null values from the destinations
  //       const nonNullDestinations = destinations.filter(
  //         (image) => image !== null
  //       );

  //       // Update the specific index in the object
  //       updatedImagesList[index] = [
  //         ...(updatedImagesList[index] || []),
  //         ...nonNullDestinations,
  //       ];

  //       // Update the corresponding field in the form
  //       setValue(`options[${index}].optionImages`, updatedImagesList[index]);

  //       console.log(getValues(`options[${index}].optionImages`));
  //       return updatedImagesList;
  //     });
  //   },
  //   [setValue]
  // );

  const handleDrop = useCallback(
    async (acceptedFiles, index) => {
      // Create object URLs for preview
      const newImages = acceptedFiles.map((file) => URL.createObjectURL(file));

      // Update image preview list
      setImagesPreviewList((prevImagesList) => {
        const updatedImagesPreview = {
          ...prevImagesList,
          [index]: [...(prevImagesList[index] || []), ...newImages],
        };

        setValue(
          `options[${index}].optionImagesPreview`,
          updatedImagesPreview[index]
        );

        return updatedImagesPreview;
      });

      // Upload images and get the URLs
      const response = await uploadImages(acceptedFiles);

      // Filter out null values from response
      const destinations = response
        ?.map((item) => item.destination)
        .filter((url) => url !== null);

      // Update image list
      setImagesList((prevImagesList) => {
        const updatedImagesList = { ...prevImagesList };

        // Ensure the array at index is initialized
        updatedImagesList[index] = [
          ...(updatedImagesList[index] || []),
          ...destinations,
        ];

        setValue(`options[${index}].optionImages`, updatedImagesList[index]);

        // console.log(getValues(`options[${index}].optionImages`));
        return updatedImagesList;
      });
    },
    [setValue, uploadImages, getValues]
  );

  console.log(imagesList);

  // const handleDrop = useCallback(
  //   (acceptedFiles, index) => {
  //     const newImages = acceptedFiles.map((file) => URL.createObjectURL(file));
  //     setImagesList((prevImagesList) => {
  //       const updatedImages = {
  //         ...prevImagesList,
  //         [index]: [...(prevImagesList[index] || []), ...newImages],
  //       };
  //       setValue(`options[${index}].optionImages`, updatedImages[index]);
  //       return updatedImages;
  //     });
  //   },
  //   [setValue]
  // );

  const removeImage = (rowIndex, imageIndex) => {
    setImagesPreviewList((prevImagesList) => {
      // Ensure the rowIndex exists and is valid
      if (!prevImagesList[rowIndex]) {
        console.warn(`Row index ${rowIndex} not found.`);
        return prevImagesList;
      }

      // Filter out the image at imageIndex
      const updatedImages = prevImagesList[rowIndex].filter(
        (_, index) => index !== imageIndex
      );

      // Update the form value
      setValue(`options[${rowIndex}].optionImagesPreview`, updatedImages);

      // Return the updated state
      return {
        ...prevImagesList,
        [rowIndex]: updatedImages,
      };
    });
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = options.findIndex((item) => item.id === active.id);
      const newIndex = options.findIndex((item) => item.id === over.id);

      if (oldIndex !== -1 && newIndex !== -1) {
        move(oldIndex, newIndex);

        const updatedOptionsList = { ...optionsList };
        const movedItemOptions = updatedOptionsList[oldIndex];
        if (oldIndex < newIndex) {
          for (let i = oldIndex; i < newIndex; i++) {
            updatedOptionsList[i] = updatedOptionsList[i + 1];
          }
        } else {
          for (let i = oldIndex; i > newIndex; i--) {
            updatedOptionsList[i] = updatedOptionsList[i - 1];
          }
        }
        updatedOptionsList[newIndex] = movedItemOptions;

        const updatedImagesList = { ...imagesList };
        const movedItemImages = updatedImagesList[oldIndex];
        if (oldIndex < newIndex) {
          for (let i = oldIndex; i < newIndex; i++) {
            updatedImagesList[i] = updatedImagesList[i + 1];
          }
        } else {
          for (let i = oldIndex; i > newIndex; i--) {
            updatedImagesList[i] = updatedImagesList[i - 1];
          }
        }
        updatedImagesList[newIndex] = movedItemImages;

        setOptionsList(updatedOptionsList);
        setImagesList(updatedImagesList);
      }
    }
  };

  const handleKeyPress = (index, e) => {
    if (e.key === "Enter" && inputValues[index]) {
      e.preventDefault();
      const trimmedValue = inputValues[index].trim();
      if (!optionsList[index]?.includes(trimmedValue)) {
        const updatedOptions = [...(optionsList[index] || []), trimmedValue];
        setOptionsList((prevOptions) => ({
          ...prevOptions,
          [index]: updatedOptions,
        }));
        setValue(`options[${index}].options`, updatedOptions);
      }
      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [index]: "",
      }));
    }
  };

  const handleRemoveTag = (index, tagToRemove) => {
    const updatedOptions = optionsList[index]?.filter(
      (tag) => tag !== tagToRemove
    );
    setOptionsList((prevOptions) => ({
      ...prevOptions,
      [index]: updatedOptions,
    }));
    setValue(`options[${index}].options`, updatedOptions);
  };

  const handleInputChange = (index, value) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [index]: value,
    }));
  };

  const handleRemoveRow = (index) => {
    // Remove the row from the form state using removeOptions
    removeOptions(index);

    // Update optionsList state
    setOptionsList((prevOptionsList) => {
      const updatedOptionsList = { ...prevOptionsList };
      delete updatedOptionsList[index];

      // Adjust the remaining indexes
      const newOptionsList = Object.keys(updatedOptionsList).reduce(
        (acc, key) => {
          const newKey = key > index ? key - 1 : key;
          acc[newKey] = updatedOptionsList[key];
          return acc;
        },
        {}
      );
      return newOptionsList;
    });

    // Update imagesList state
    setImagesList((prevImagesList) => {
      const updatedImagesList = { ...prevImagesList };
      delete updatedImagesList[index];

      // Adjust the remaining indexes
      const newImagesList = Object.keys(updatedImagesList).reduce(
        (acc, key) => {
          const newKey = key > index ? key - 1 : key;
          acc[newKey] = updatedImagesList[key];
          return acc;
        },
        {}
      );
      return newImagesList;
    });

    // Update inputValues state
    setInputValues((prevInputValues) => {
      const updatedInputValues = { ...prevInputValues };
      delete updatedInputValues[index];

      // Adjust the remaining indexes
      const newInputValues = Object.keys(updatedInputValues).reduce(
        (acc, key) => {
          const newKey = key > index ? key - 1 : key;
          acc[newKey] = updatedInputValues[key];
          return acc;
        },
        {}
      );
      return newInputValues;
    });
  };

  console.log(state);

  useEffect(() => {
    if (state) {
      const { options } = state;

      // Initialize inputValues, optionsList, and imagesList from the state
      const initialInputValues = {};
      const initialOptionsList = {};
      const initialImagesList = {};
      const serverImagesList = {};

      options.forEach((option, index) => {
        initialInputValues[index] = ""; // Assuming inputs are empty initially
        initialOptionsList[index] = option.options || [];
        initialImagesList[index] = option.optionImages || [];
        serverImagesList[index] = option.images || [];

        // Set form values using setValue
        setValue(`options[${index}].options`, option.options);
        setValue(`options[${index}].optionImages`, option.optionImages);
        setValue(`options[${index}].optionImagesPreview`, option.images);
        // setValue(`options[${index}].name`, option.name);
        // setValue(`options[${index}].sortOrder`, option.sortOrder);
      });

      setInputValues(initialInputValues);
      setOptionsList(initialOptionsList);
      setImagesList(initialImagesList);
      setServerImagesList(serverImagesList);
    }
  }, [state, setValue]);

  return (
    <div className="mb-3">
      <Card.Body className="" style={{ padding: "5px 20px" }}>
        <Row className="gx-2">
          <Col md="10">
            <Form.Group>
              {/* <FloatingLabel controlId="floatingInput" label="Name"> */}
              <Form.Control
                type="text"
                placeholder="Name"
                title="Name"
                className="py-2"
                isInvalid={!!errors.name}
                {...register("name")}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name?.message}
              </Form.Control.Feedback>
              {/* </FloatingLabel> */}
            </Form.Group>
          </Col>
          <Col md="2">
            <Form.Check
              type="checkbox"
              id="defaultSwitch"
              label="Active"
              className="mt-2"
              defaultChecked={!state}
              isInvalid={!!errors.status}
              {...register("status")}
            />
          </Col>
        </Row>
        <Row className="gx-2">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCorners}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={options.map((item) => item.id)}
              strategy={verticalListSortingStrategy}
            >
              <Table responsive>
                <thead className="bg-200">
                  <tr>
                    <th scope="col" className="w-0 fs-10 text-center">
                      Order
                    </th>
                    <th scope="col" className="fs-10">
                      Name
                    </th>
                    <th scope="col" className="fs-10">
                      Options
                    </th>
                    <th scope="col" className="fs-10">
                      Images
                    </th>
                    <th scope="col"></th>
                    <th scope="col" style={{ padding: "10px" }}>
                      <ActionButton
                        icon="plus"
                        variant="success"
                        title="Add"
                        className="px-1 py-0"
                        onClick={() => appendOptions({})}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {options.map((item, index) => (
                    <SortableItem key={item.id} id={item.id}>
                      <td style={{ padding: "10px" }}>
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          isInvalid={!!errors.options?.[index]?.name}
                          {...register(`options[${index}].name`)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.options?.[index]?.name?.message}
                        </Form.Control.Feedback>
                      </td>
                      <td style={{ width: "30%", padding: "10px" }}>
                        <div className="form-control d-flex flex-wrap align-items-center">
                          {optionsList[index]?.map((tag, tagIndex) => (
                            <SubtleBadge
                              key={tagIndex}
                              className="mr-2 mb-1 mx-1"
                            >
                              {tag}
                              <span
                                className="ml-2"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleRemoveTag(index, tag)}
                              >
                                &times;
                              </span>
                            </SubtleBadge>
                          ))}
                          <input
                            type="text"
                            className="border-0 flex-grow-1"
                            style={{
                              outline: "none",
                              color: "black",
                              paddingBottom: "0px",
                              paddingTop: "0px",
                            }}
                            value={inputValues[index] || ""}
                            onChange={(e) =>
                              handleInputChange(index, e.target.value)
                            }
                            onKeyPress={(e) => handleKeyPress(index, e)}
                            placeholder="Tags"
                          />
                        </div>
                      </td>

                      <td style={{ padding: "10px" }}>
                        <Dropzone onDrop={handleDrop} index={index} />
                      </td>
                      <td style={{ width: "30%", padding: "10px" }}>
                        {/* <LightBoxLatestExample
                            images={imagesList[index] || []}
                          >
                            {(setImgIndex) => (
                              <Row className="g-2">
                                {(imagesList[index] || []).map(
                                  (image, imgIndex) => (
                                    <>
                                      {console.log(image)}
                                      {console.log(imgIndex)}
                                      <Col
                                        xs={4}
                                        md={4}
                                        key={imgIndex}
                                        className="position-relative"
                                      >
                                        <Image
                                          src={`data:image/png;base64, ${image?.fileBase64}`}
                                          fluid
                                          rounded
                                          className="cursor-pointer"
                                          onClick={() => setImgIndex(imgIndex)}
                                        />
                                        <Button
                                          variant="danger"
                                          size="sm"
                                          onClick={() =>
                                            removeImage(index, imgIndex)
                                          }
                                          className="position-absolute top-0 end-0"
                                          style={{ padding: "0px 1px 0px 1px" }}
                                        >
                                          &times;
                                        </Button>
                                      </Col>
                                    </>
                                  )
                                )}
                              </Row>
                            )}
                          </LightBoxLatestExample> */}

                        {/* <LightBoxLatestExample
                          images={imagesPreviewList[index] || []}
                        >
                          {(setImgIndex) => (
                            <Row className="g-2">
                              {(imagesPreviewList[index] || []).map(
                                (image, imgIndex) => (
                                  <Col
                                    xs={4}
                                    md={4}
                                    key={imgIndex}
                                    className="position-relative"
                                  >
                                    <Image
                                      src={image}
                                      // src={`data:image/png;base64,${image?.fileBase64}`}
                                      fluid
                                      rounded
                                      className="cursor-pointer"
                                      onClick={() => setImgIndex(imgIndex)}
                                    />
                                    <Button
                                      variant="danger"
                                      size="sm"
                                      onClick={() =>
                                        removeImage(index, imgIndex)
                                      }
                                      className="position-absolute top-0 end-0"
                                      style={{ padding: "0px 1px 0px 1px" }}
                                    >
                                      &times;
                                    </Button>
                                  </Col>
                                )
                              )}
                            </Row>
                          )}
                        </LightBoxLatestExample>
                        <LightBoxLatestExample
                          images={(serverImagesList[index] || []).map(
                            (image) =>
                              `data:image/png;base64,${image?.fileBase64}`
                          )}
                          // images={imagesPreviewList[index] || []}
                        >
                          {(setImgIndex) => (
                            <Row className="g-2">
                              {(serverImagesList[index] || []).map(
                                (image, imgIndex) => (
                                  <Col
                                    xs={4}
                                    md={4}
                                    key={imgIndex}
                                    className="position-relative"
                                  >
                                    <Image
                                      // src={image}
                                      src={`data:image/png;base64,${image?.fileBase64}`}
                                      fluid
                                      rounded
                                      className="cursor-pointer"
                                      onClick={() => setImgIndex(imgIndex)}
                                    />
                                    <Button
                                      variant="danger"
                                      size="sm"
                                      onClick={() =>
                                        removeImage(index, imgIndex)
                                      }
                                      className="position-absolute top-0 end-0"
                                      style={{ padding: "0px 1px 0px 1px" }}
                                    >
                                      &times;
                                    </Button>
                                  </Col>
                                )
                              )}
                            </Row>
                          )}
                        </LightBoxLatestExample> */}

                        <MyImageGallery
                          index={index}
                          imagesPreviewList={imagesPreviewList}
                          serverImagesList={serverImagesList}
                          removeImage={removeImage}
                        />
                        {/* <LightBoxLatestExample images={combinedImages}>
                          {(setImgIndex) => (
                            <Row className="g-2">
                              {combinedImages.map((image, imgIndex) => (
                                <Col
                                  xs={4}
                                  md={4}
                                  key={imgIndex}
                                  className="position-relative"
                                >
                                  <Image
                                    src={image}
                                    fluid
                                    rounded
                                    className="cursor-pointer"
                                    onClick={() => setImgIndex(imgIndex)}
                                  />
                                  <Button
                                    variant="danger"
                                    size="sm"
                                    onClick={() => removeImage(index, imgIndex)}
                                    className="position-absolute top-0 end-0"
                                    style={{ padding: "0px 1px 0px 1px" }}
                                  >
                                    &times;
                                  </Button>
                                </Col>
                              ))}
                            </Row>
                          )}
                        </LightBoxLatestExample> */}
                      </td>
                      <td style={{ padding: "10px" }} className="text-center">
                        <ActionButton
                          icon="trash-alt"
                          title="Delete"
                          variant="danger"
                          className="px-1 py-0"
                          onClick={() => handleRemoveRow(index)}
                        />
                      </td>
                    </SortableItem>
                  ))}
                </tbody>
              </Table>
            </SortableContext>
          </DndContext>
        </Row>
      </Card.Body>
    </div>
  );
};

const MyImageGallery = ({
  index,
  imagesPreviewList,
  serverImagesList,
  removeImage,
}) => {
  // Combine the preview images and server images
  const combinedImages = [
    ...(imagesPreviewList[index] || []),
    ...(serverImagesList[index]?.map(
      (image) => `data:image/png;base64,${image?.fileBase64}`
    ) || []),
  ];

  return (
    <LightBoxLatestExample images={combinedImages}>
      {(setImgIndex) => (
        <Row className="g-2">
          {combinedImages.map((image, imgIndex) => (
            <Col xs={4} md={4} key={imgIndex} className="position-relative">
              <Image
                src={image}
                fluid
                rounded
                className="cursor-pointer"
                onClick={() => setImgIndex(imgIndex)}
              />
              <Button
                variant="danger"
                size="sm"
                onClick={() => removeImage(index, imgIndex)}
                className="position-absolute top-0 end-0"
                style={{ padding: "0px 1px 0px 1px" }}
              >
                &times;
              </Button>
            </Col>
          ))}
        </Row>
      )}
    </LightBoxLatestExample>
  );
};

export default VariantsBasicInformation;
