import {
  closestCorners,
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import ActionButton from "components/common/ActionButton";
import { useFetchCategories } from "hooks/useFetchCategories";
import { useFetchDetailsFieldSets } from "hooks/useFetchDetailFieldSets";
import { useFetchVariants } from "hooks/useFetchDetailVariants";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { useNavigationGuard } from "routes/Gaurd/useNavigationGuard";
import useNavigationPrompt from "routes/Gaurd/useNavigationPrompt";
import usePrompt from "routes/Gaurd/useNavigationPrompt";

const SortableItem = ({ id, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <tr ref={setNodeRef} style={style}>
      <td
        {...listeners}
        {...attributes}
        style={{
          cursor: "grab",
          textAlign: "center",
          width: "10px",
          // paddingTop: "15px",
        }}
      >
        ☰
      </td>
      {children}
    </tr>
  );
};

const CategoryVariantsBasicInformation = () => {
  const {
    register,
    setValue,
    clearErrors,
    control,
    formState: { errors, isDirty },
  } = useFormContext();

  const {
    fields: options,
    append: appendOptions,
    remove: removeOptions,
    move,
  } = useFieldArray({
    control,
    name: "group",
  });

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  );

  // useNavigationPrompt(isDirty);

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = options.findIndex((item) => item.id === active.id);
      const newIndex = options.findIndex((item) => item.id === over.id);
      move(oldIndex, newIndex);
    }
  };

  const { state } = useLocation();
  const navigate = useNavigate();

  const { data: categoriesData, isLoading: categoriesLoading } =
    useFetchCategories();

  const { data: variantsData, isLoading: variantsDataLoading } =
    useFetchVariants();

  useEffect(() => {
    register("categoryId", { required: true });
  }, [register]);

  useEffect(() => {
    if (state) {
      setValue("categoryId", state.categoryId);
    }
  }, [state, setValue]);

  const handleSelectChange = (selectedOption) => {
    setValue("categoryId", selectedOption ? selectedOption.value : "");
    if (selectedOption) {
      clearErrors("categoryId");
    }
  };

  const handleInputFocus = () => {
    clearErrors("categoryId");
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: errors.categoryId ? "red" : provided.borderColor,
      "&:hover": {
        borderColor: errors.categoryId
          ? "red"
          : provided["&:hover"].borderColor,
      },
    }),
    input: (provided) => ({
      ...provided,
      color: "black",
      paddingTop: "4px",
      paddingBottom: "4px",
      // padding: "12px", // Set the input text color to black
    }),

    option: (provided, state) => ({
      ...provided,
      paddingLeft: `${state.data.level * 10}px`,
    }),

    menu: (provided) => ({
      ...provided,
      color: "black",
      zIndex: 9999, // Adjust the zIndex as needed
    }),
  };

  const flattenOptions = (nodes, level = 1) => {
    return nodes.reduce((acc, node) => {
      const label = `${" ".repeat(level - 1)} ${node.name}`;
      acc.push({ value: node.id, label, parent: node.parent, level });

      if (node.children && node.children.length > 0) {
        acc.push(...flattenOptions(node.children, level + 1));
      }

      return acc;
    }, []);
  };

  const createHierarchy = (data) => {
    const lookup = {};
    const result = [];

    data?.forEach((item) => {
      lookup[item.id] = { ...item, children: [] };
    });

    data?.forEach((item) => {
      const { id, parent } = item;
      if (parent === 0) {
        result.push(lookup[id]);
      } else if (lookup[parent]) {
        lookup[parent].children.push(lookup[id]);
      }
    });

    return result;
  };

  const hierarchicalData = createHierarchy(categoriesData?.data?.data);
  const flatOptions = flattenOptions(hierarchicalData);
  // const flatOptions = [];

  let initialSelectedOption = flatOptions.find(
    (option) => option.value === state?.categoryId
  );

  if (initialSelectedOption == undefined) {
    initialSelectedOption = state
      ? { label: state?.categoryId, value: state?.categoryId }
      : undefined;
  }

  // if (categoriesLoading || variantsDataLoading) {
  //   return <Spinner variant="primary" animation="border" />;
  // }

  return (
    <div className="">
      {/* <Card.Header as="h6" className="bg-body-tertiary">
        Basic information
      </Card.Header> */}
      {categoriesLoading || variantsDataLoading ? (
        <div className="d-flex py-3 justify-content-center align-items-center">
          <Button variant="falcon-default" size="sm" className="fs-10">
            Loading...
          </Button>
        </div>
      ) : (
        <Card.Body className="" style={{ padding: "5px 20px" }}>
          <Row className="gx-2">
            <Col md="5">
              <Form.Group>
                {/* <Form.Label>First name:</Form.Label> */}
                <Select
                  options={flatOptions}
                  isClearable
                  placeholder={
                    <h6 className="mb-2 mb-md-0">
                      Category{" "}
                      {/* <span style={{ color: "red", fontSize: "12px" }}>*</span> */}
                    </h6>
                  }
                  className={`${errors.categoryId ? "is-invalid" : ""}`}
                  styles={customStyles}
                  autoFocus
                  onChange={handleSelectChange}
                  onFocus={handleInputFocus}
                  onInputChange={handleInputFocus}
                  defaultValue={initialSelectedOption}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.categoryId?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="5">
              <Form.Group>
                {/* <FloatingLabel
                controlId="floatingInput"
                label="Name"
                className=""
              > */}
                <Form.Control
                  type="text"
                  placeholder="Name"
                  title="Name"
                  className="py-2"
                  isInvalid={!!errors.name}
                  {...register("name")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.message}
                </Form.Control.Feedback>
                {/* </FloatingLabel> */}
              </Form.Group>
            </Col>
            <Col md="2">
              <Form.Check
                type="checkbox"
                id="defaultSwitch"
                label="Active"
                className="mt-2"
                defaultChecked={!state}
                isInvalid={!!errors.status}
                {...register("status")}
              />
            </Col>
          </Row>
          {/* <div className="shadow-none border my-3"> */}
          <Row className="gx-2 gy-3 ">
            <DndContext
              sensors={sensors}
              collisionDetection={closestCorners}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={options.map((item) => item.id)}
                strategy={verticalListSortingStrategy}
              >
                <Table responsive>
                  <thead className="bg-200">
                    <tr>
                      <th scope="col" className="w-0 fs-10 text-center">
                        Order
                      </th>

                      <th scope="col" className="fs-10">
                        Detail Variants
                      </th>

                      <th
                        style={{ width: "10px" }}
                        scope="col"
                        className="fs-10 text-center"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        style={{ padding: "9px", width: "10px" }}
                        className=" text-end"
                      >
                        <ActionButton
                          icon="plus"
                          variant="success"
                          title="Add"
                          className="px-1 py-0"
                          onClick={() => appendOptions({})}
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {options.map(
                      (item, index) => (
                        // index === options.length - 1 ? (
                        //   <tr key={item.id}>
                        //     <td style={{ width: 0 }}></td>
                        //     <td>
                        //       <Form.Group
                        //         className="mb-3"
                        //         controlId="formGroupName"
                        //       >
                        //         <Form.Select
                        //           aria-label="options"
                        //           isInvalid={!!errors.options?.[index]?.variantId}
                        //           {...register(`options[${index}].variantId`)}
                        //         >
                        //           <option value="">Select</option>
                        //           {variantsData?.data?.data?.map((d) => (
                        //             <option key={d.id} value={d.id}>
                        //               {d.name}
                        //             </option>
                        //           ))}
                        //         </Form.Select>
                        //         <Form.Control.Feedback type="invalid">
                        //           {errors.options?.[index]?.variantId?.message}
                        //         </Form.Control.Feedback>
                        //       </Form.Group>
                        //     </td>
                        //     <td className="text-center">
                        //       <Form.Check
                        //         type="switch"
                        //         id="defaultSwitch"
                        //         defaultChecked={!state}
                        //         isInvalid={!!errors.options?.[index]?.status}
                        //         {...register(`options[${index}].status`)}
                        //       />
                        //     </td>

                        //     <td className="text-center">
                        //       <ActionButton
                        //         icon="plus"
                        //         variant="success"
                        //         title="Add"
                        //         className="px-1 py-0"
                        //         onClick={() => appendOptions({})}
                        //       />
                        //     </td>
                        //   </tr>
                        // ) : (
                        <SortableItem key={item.id} id={item.id}>
                          <td style={{ padding: "5px" }}>
                            <Form.Group controlId="formGroupName">
                              <Form.Select
                                aria-label="options"
                                isInvalid={!!errors.options?.[index]?.variantId}
                                {...register(`options[${index}].variantId`)}
                              >
                                <option value="">Select</option>
                                {variantsData?.data?.data?.map((d) => (
                                  <option value={d.id}>{d.name}</option>
                                ))}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                {errors.options?.[index]?.variantId?.message}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </td>
                          <td
                            style={{ padding: "5px" }}
                            className="text-center"
                          >
                            <Form.Check
                              type="checkbox"
                              id="defaultSwitch"
                              className="mt-1"
                              defaultChecked={!state}
                              isInvalid={!!errors.options?.[index]?.status}
                              {...register(`options[${index}].status`)}
                            />
                          </td>
                          <td
                            style={{ padding: "5px" }}
                            className="text-center"
                          >
                            <ActionButton
                              icon="trash-alt"
                              title="Delete"
                              variant="danger"
                              className="px-1 py-0"
                              onClick={() => removeOptions(index)}
                            />
                          </td>
                        </SortableItem>
                      )
                      // )
                    )}
                  </tbody>
                </Table>
              </SortableContext>
            </DndContext>
          </Row>
          {/* </div> */}
        </Card.Body>
      )}
    </div>
  );
};

export default CategoryVariantsBasicInformation;
