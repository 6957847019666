import ActionButton from "components/common/ActionButton";
import { useAppContext } from "Main";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { FaPlusSquare } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import FieldPropertiesSubModal from "./FieldPropertiesSubModal";
import { Checkbox } from "@mui/material";

const FieldPropertiesModal = ({
  show,
  setShow,
  applyProperties,
  fieldKey,
  data,
}) => {
  const {
    config: { isDark },
  } = useAppContext();

  const [formData, setFormData] = useState(
    data?.properties || {
      name: "",
      label: "",
      placeholder: "",
      readOnly: false,
      required: false,
      regex: "",
      defaultValue: "",
      sortOrder: 0,
      groupForRadioOrCheckBox: "",
      listValues: "",
      options: [{ name: "", children: [] }],
      styles: {},
    }
  );

  const [subFieldsModal, setSubFieldsModal] = useState(false);
  const [subCurrentFieldKey, setSubCurrentFieldKey] = useState(null);
  const [hierarchicalOptions, setHierarchicalOptions] = useState(
    data?.properties?.options || [{ name: "", children: [] }]
  );

  const handleClose = () => {
    setShow(!show);
  };

  const handleSubModalProperties = (key) => {
    setSubCurrentFieldKey(key);
    setSubFieldsModal(true);
  };

  const handleChange = ({ target }) => {
    const { name, type, checked, value, valueAsNumber } = target;
    // const newValue = type === "checkbox" ? checked : value;
    // const numberValue = type === "number" ? valueAsNumber : value;

    let newValue;

    if (type === "checkbox") {
      newValue = checked;
    } else if (type === "number") {
      newValue = valueAsNumber;
    } else {
      newValue = value;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
    }));
  };

  const handleHierarchicalChange = (index, event) => {
    const { name, value } = event.target;
    const updatedHierarchicalOptions = [...hierarchicalOptions];
    updatedHierarchicalOptions[index] = {
      ...updatedHierarchicalOptions[index],
      [name]: value,
    };
    setHierarchicalOptions(updatedHierarchicalOptions);
    setFormData((prevFormData) => ({
      ...prevFormData,
      options: updatedHierarchicalOptions,
    }));
  };

  // const handleHierarchicalChange = (index, event) => {
  //   const { name, value } = event.target;
  //   setHierarchicalOptions((prevOptions) => {
  //     const updatedOptions = [...prevOptions];
  //     updatedOptions[index] = {
  //       ...updatedOptions[index],
  //       [name]: value,
  //     };
  //     return updatedOptions;
  //   });

  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     options: hierarchicalOptions.map((option, idx) =>
  //       idx === index
  //         ? {
  //             ...option,
  //             [name]: value,
  //           }
  //         : option
  //     ),
  //   }));
  // };

  const addHierarchicalOption = () => {
    setHierarchicalOptions([
      ...hierarchicalOptions,
      { name: "", children: [] },
    ]);
  };

  const removeHierarchicalOption = (index) => {
    setHierarchicalOptions(hierarchicalOptions.filter((_, i) => i !== index));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    applyProperties(fieldKey, formData);
    handleClose();
  };

  const applySubProperties = (key, data) => {
    const updatedHierarchicalOptions = hierarchicalOptions.map(
      (option, index) => {
        if (index === key) {
          return { ...option, children: [...option.children, data] };
        }
        return option;
      }
    );

    setHierarchicalOptions(updatedHierarchicalOptions);
    setFormData((prevFormData) => ({
      ...prevFormData,
      options: updatedHierarchicalOptions,
    }));
  };

  // useEffect(() => {
  //   setFormData({
  //     name: "",
  //     label: "",
  //     placeholder: "",
  //     readOnly: false,
  //     required: false,
  //     regex: "",
  //     defaultValue: "",
  //     sortOrder: "",
  //     groupForRadioOrCheckBox: "",
  //     listValues: "",
  //     options: hierarchicalOptions,
  //     styles: {},
  //   });
  //   setHierarchicalOptions([{ name: "", children: [] }]);
  // }, [show]);

  useEffect(() => {
    setFormData(
      data?.properties || {
        name: "",
        label: "",
        placeholder: "",
        readOnly: false,
        required: false,
        regex: "",
        defaultValue: "",
        sortOrder: 0,
        groupForRadioOrCheckBox: "",
        listValues: "",
        options: hierarchicalOptions,
        styles: {},
      }
    );
    setHierarchicalOptions(
      data?.properties?.options || [{ name: "", children: [] }]
    );
  }, [show, data]);

  return (
    <>
      <Modal
        // size="lg"
        show={show}
        onHide={handleClose}
        contentClassName="border"
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header
            closeButton
            closeVariant={isDark ? "white" : undefined}
            className="bg-body-tertiary px-x1 border-bottom-0"
          >
            <Modal.Title as="h5">Field Properties</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-x1">
            <div className="mt-2 mb-4 grid grid-cols-2 gap-4">
              <div className="d-flex justify-content-between mb-1">
                <label>Name:</label>
                <input
                  type="text"
                  name="name"
                  value={formData?.name}
                  onChange={handleChange}
                  className="border border-gray-300 rounded px-1"
                />
              </div>
              <div className="d-flex justify-content-between mb-1">
                <label>Label:</label>
                <input
                  type="text"
                  name="label"
                  value={formData?.label}
                  onChange={handleChange}
                  className="border border-gray-300 rounded px-1"
                />
              </div>
              <div className="d-flex justify-content-between mb-1">
                <label>Placeholder:</label>
                <input
                  type="text"
                  name="placeholder"
                  value={formData?.placeholder}
                  onChange={handleChange}
                  className="border border-gray-300 rounded px-1"
                />
              </div>
              <div className="d-flex justify-content-between mb-1">
                <label>Background Color:</label>
                <input
                  type="text"
                  name="backgroundColor"
                  value={formData?.styles?.backgroundColor || ""}
                  onChange={(e) =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      styles: {
                        ...prevFormData.styles,
                        backgroundColor: e.target.value,
                      },
                    }))
                  }
                  className="border border-gray-300 rounded px-1"
                />
              </div>
              <div className="d-flex justify-content-between mb-1">
                <label>Color:</label>
                <input
                  type="text"
                  name="color"
                  value={formData?.styles?.color || ""}
                  onChange={(e) =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      styles: {
                        ...prevFormData.styles,
                        color: e.target.value,
                      },
                    }))
                  }
                  className="border border-gray-300 rounded px-1"
                />
              </div>
              <div className="d-flex justify-content-between mb-1">
                <label>Width:</label>
                <input
                  type="number"
                  name="width"
                  value={formData?.styles?.width || ""}
                  onChange={(e) =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      styles: {
                        ...prevFormData.styles,
                        width: e.target.valueAsNumber,
                      },
                    }))
                  }
                  className="border border-gray-300 rounded px-1"
                />
              </div>
              <div className="d-flex justify-content-between mb-1">
                <label>RegEx:</label>
                <input
                  type="text"
                  name="regex"
                  value={formData?.regex}
                  onChange={handleChange}
                  className="border border-gray-300 rounded px-1"
                />
              </div>
              <div className="d-flex justify-content-between mb-1">
                <label>SortOrder:</label>
                <input
                  type="number"
                  name="sortOrder"
                  value={formData?.sortOrder}
                  onChange={handleChange}
                  className="border border-gray-300 rounded px-1"
                />
              </div>
              <div className="d-flex justify-content-between mb-1">
                <label>DefaultValue:</label>
                <input
                  type="text"
                  name="defaultValue"
                  value={formData?.defaultValue}
                  onChange={handleChange}
                  className="border border-gray-300 rounded px-1"
                />
              </div>
              {(fieldKey?.startsWith("select") ||
                fieldKey?.startsWith("multiSelect")) && (
                <div className="d-flex justify-content-between mb-1">
                  <label className="w-[325px]">List Values:</label>
                  <input
                    type="text"
                    name="listValues"
                    value={formData?.listValues}
                    onChange={handleChange}
                    placeholder="option1|option2|option3"
                    className="border border-gray-300 rounded px-1 w-4/5"
                  />
                </div>
              )}
              {(fieldKey?.startsWith("radioBox") ||
                fieldKey?.startsWith("checkbox")) && (
                <div className="d-flex justify-content-between mb-1">
                  <label>Group:</label>
                  <input
                    type="text"
                    name="groupForRadioOrCheckBox"
                    value={formData?.groupForRadioOrCheckBox}
                    onChange={handleChange}
                    className="border border-gray-300 rounded px-1"
                  />
                </div>
              )}
              <div className="d-flex justify-content-between mb-1">
                <label>Is Read Only:</label>
                {/* <input
                  type="checkbox"
                  name="readOnly"
                  checked={formData?.readOnly}
                  onChange={handleChange}
                  className="border border-gray-300 rounded px-1"
                /> */}
                <Checkbox
                  name="readOnly"
                  onChange={handleChange}
                  checked={formData?.readOnly}
                  className="p-0"
                />
              </div>
              <div className="d-flex justify-content-between mb-1">
                <label>Is Required:</label>
                <Checkbox
                  name="required"
                  onChange={handleChange}
                  checked={formData?.required}
                  className="p-0"
                />
                {/* <input
                  type="checkbox"
                  name="required"
                  checked={formData?.required}
                  onChange={handleChange}
                  className="border border-gray-300 rounded px-1"
                /> */}
              </div>
            </div>
            {/* ================== Hierarchical Select ===================== */}
            {fieldKey?.startsWith("hierarchicalSelect") && (
              <div className="mt-4">
                <Table responsive>
                  <thead className="bg-200">
                    <tr>
                      <th scope="col" className="fs-10">
                        Hierarchical Select
                      </th>
                      <th className="text-end" scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {hierarchicalOptions.map((option, index) => (
                      <tr key={`hierarchical-option-${index}`}>
                        <td className="px-4 py-2">
                          <input
                            type="text"
                            name="name"
                            value={option.name}
                            onChange={(e) => handleHierarchicalChange(index, e)}
                            style={{ width: "100%" }}
                            className="border border-gray-300 rounded px-1 "
                          />
                        </td>

                        <td className="px-4 py-2 text-center">
                          {index === hierarchicalOptions.length - 1 ? (
                            <div className="flex items-center gap-2 justify-center">
                              <ActionButton
                                icon="plus"
                                variant="info"
                                title="Add Child"
                                className="px-1 mx-2 py-0"
                                onClick={() => handleSubModalProperties(index)}
                              />
                              <ActionButton
                                icon="plus"
                                variant="success"
                                title="Add Parent"
                                className="px-1 py-0"
                                onClick={addHierarchicalOption}
                              />
                            </div>
                          ) : (
                            <div className="flex items-center gap-2 justify-center">
                              <ActionButton
                                icon="plus"
                                variant="info"
                                title="Add Child"
                                className="px-1 mx-2 py-0"
                                onClick={() => handleSubModalProperties(index)}
                              />

                              <ActionButton
                                icon="trash-alt"
                                title="Delete"
                                variant="danger"
                                className="px-1 py-0"
                                onClick={() => removeHierarchicalOption(index)}
                              />
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer className="bg-body-tertiary px-x1 border-top-0">
            <div onClick={handleClose} className="px-4 mx-0 cursor-pointer">
              Cancel
            </div>
            <Button
              onClick={handleSubmit}
              variant="primary"
              type="button"
              className="px-4 mx-0"
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
        <FieldPropertiesSubModal
          setShow={setSubFieldsModal}
          show={subFieldsModal}
          applySubProperties={applySubProperties}
          subFieldKey={subCurrentFieldKey}
        />
      </Modal>
    </>
  );
};

export default FieldPropertiesModal;
