import BreadCrumb from "components/breadcrumb/BreadCrumb";
import IconButton from "components/common/IconButton";
import React, { createElement, useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  FormControl,
  InputGroup,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import * as ReactIcons from "react-icons/bs";
import * as ReactIconsFa from "react-icons/fa6";
import * as ReactIconsMd from "react-icons/md";
import * as ReactIconsGi from "react-icons/gi";
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";
import AddCategoryModal from "./Modals/AddNewCategory";
import "./styles.css";
import { useFetchCategories } from "hooks/useFetchCategories";
import { FaSearch } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CollapseIcon from "../../../../../src/assets/img/icons/collapse.ico";
import ExpandIcon from "../../../../../src/assets/img/icons/expand.ico";
import { Col, Row } from "react-bootstrap";

// Initial data
// const initialData = [
//   {
//     id: "1",
//     name: "Parent One",
//     children: [
//       { id: "2", name: "1-1 Child" },
//       {
//         id: "3",
//         name: "Child 1-2",
//         children: [
//           { id: "4", name: "Grandchild 1-2-1" },
//           { id: "5", name: "Grandchild 1-2-2" },
//         ],
//       },
//     ],
//   },
//   {
//     id: "6",
//     name: "Parent Two",
//     children: [
//       { id: "7", name: "2-1 Child" },
//       { id: "8", name: "2-2 Child" },
//     ],
//   },
// ];
// const flatData = [
//   {
//     name: "string",
//     description: "string",
//     icons: "string",
//     logo: "string",
//     parent: 0,
//     cLevel: 1,
//     id: 1,
//     status: true,
//     insertedBy: "TODO",
//     insertedOn: "2024-08-14T12:37:46.13",
//     updatedBy: "TODO",
//     updatedOn: "2024-08-14T12:37:46.13",
//   },
//   {
//     name: "Test From Swagger",
//     description: "Description",
//     icons: "FaPlus",
//     logo: "",
//     parent: 0,
//     cLevel: 1,
//     id: 2,
//     status: true,
//     insertedBy: "TODO",
//     insertedOn: "2024-08-15T01:22:28.517",
//     updatedBy: "TODO",
//     updatedOn: "2024-08-15T01:22:28.517",
//   },
//   {
//     name: "Test From Swagger Child",
//     description: "",
//     icons: "FaPlus",
//     logo: "",
//     parent: 1,
//     cLevel: 2,
//     id: 3,
//     status: true,
//     insertedBy: "TODO",
//     insertedOn: "2024-08-15T01:41:54.673",
//     updatedBy: "TODO",
//     updatedOn: "2024-08-15T01:41:54.673",
//   },
// ];

// const createHierarchy = (data) => {
//   const lookup = {};
//   const result = [];

//   // Initialize lookup table with ids and prepare empty children arrays
//   data.forEach((item) => {
//     lookup[item.id] = { ...item, children: [] };
//   });

//   // Build hierarchy
//   data.forEach((item) => {
//     const { id, parent } = item;
//     if (parent === 0) {
//       result.push(lookup[id]);
//     } else if (lookup[parent]) {
//       lookup[parent].children.push(lookup[id]);
//     }
//   });

//   return result;
// };

// const hierarchicalData = createHierarchy(flatData);

const CategoriesPage = () => {
  const [data, setData] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedNode, setSelectedNode] = useState(null);
  const [nodeClick, setNodeClick] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const CombinedIcons = { ...ReactIcons, ...ReactIconsFa, ...ReactIconsMd, ...ReactIconsGi };

  const createHierarchy = (data) => {
    const lookup = {};
    const result = [];

    // Initialize lookup table with ids and prepare empty children arrays
    data?.forEach((item) => {
      lookup[item.id] = { ...item, children: [] };
    });

    // Build hierarchy
    data?.forEach((item) => {
      const { id, parent } = item;
      if (parent === 0) {
        result.push(lookup[id]);
      } else if (lookup[parent]) {
        lookup[parent].children.push(lookup[id]);
      }
    });

    return result;
  };

  const { data: categoriesData, isLoading } = useFetchCategories();

  useEffect(() => {
    const hierarchicalData = createHierarchy(categoriesData?.data?.data || []);
    setData(hierarchicalData);
  }, [categoriesData]);

  // Handle expanding all nodes
  const handleExpandAll = () => {
    const allNodeIds = [];
    const traverse = (nodes) => {
      nodes.forEach((node) => {
        allNodeIds.push(node.id);
        if (node.children) {
          traverse(node.children);
        }
      });
    };
    traverse(data);
    setExpanded(allNodeIds);
  };

  // Handle collapsing all nodes
  const handleCollapseAll = () => {
    setExpanded([]);
  };

  // Toggle a specific node
  const toggleNode = (nodeId) => {
    setExpanded((prevExpanded) =>
      prevExpanded.includes(nodeId)
        ? prevExpanded.filter((id) => id !== nodeId)
        : [...prevExpanded, nodeId]
    );
  };

  const handleNodeDoubleClick = (node) => {
    setSelectedNode(node);
    setModalOpen(true);
  };

  const handleNodeClick = (node) => {
    setNodeClick(node);
    // setModalOpen(true);
  };

  // Filter tree nodes based on search query
  const filterTree = (nodes) => {
    return nodes
      .map((node) => {
        if (node.name.toLowerCase().includes(searchQuery.toLowerCase())) {
          return node;
        }
        if (node.children) {
          const filteredChildren = filterTree(node.children);
          if (filteredChildren.length) {
            return { ...node, children: filteredChildren };
          }
        }
        return null;
      })
      .filter((node) => node);
  };

  // Render tree nodes
  const renderTree = (nodes) =>
    nodes.map((node) => (
      <div key={node.id}>
        <div
          className={`d-flex align-items-center cursor-pointer  ${
            expanded.includes(node.id) ? "expanded" : ""
          }`}
          onDoubleClick={() => handleNodeDoubleClick(node)}
          onClick={() => handleNodeClick(node)}
        >
          {node.children && (
            <span
              onClick={(e) => {
                e.stopPropagation();
                toggleNode(node.id);
              }}
            >
              {expanded.includes(node.id) ? (
                <CiSquareMinus style={{ marginRight: "5px" }} />
              ) : (
                <CiSquarePlus style={{ marginRight: "5px" }} />
              )}
            </span>
          )}
          <div
            style={{
              display: "flex ",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            {CombinedIcons[node.icons]
              ? createElement(CombinedIcons[node.icons])
              : null}
            <span
              // className={`px-2 rounded`}
              className={`${
                nodeClick?.id === node?.id && "selected-node"
              } px-2 rounded`}
            >
              {node.name}
            </span>
          </div>
        </div>
        {node.children && expanded.includes(node.id) && (
          <div className="tree-node-children" style={{ marginLeft: "40px" }}>
            {renderTree(node.children)}
          </div>
        )}
      </div>
    ));

  // Effect to expand relevant nodes based on search query
  useEffect(() => {
    if (searchQuery === "") {
      setExpanded([]);
    } else {
      const matchingNodeIds = [];
      const traverse = (nodes, parentIds = []) => {
        nodes.forEach((node) => {
          if (node.name.toLowerCase().includes(searchQuery.toLowerCase())) {
            matchingNodeIds.push(...parentIds, node.id);
          }
          if (node.children) {
            traverse(node.children, [...parentIds, node.id]);
          }
        });
      };
      traverse(data);
      setExpanded([...new Set(matchingNodeIds)]);
    }
  }, [searchQuery]);

  // if (isLoading) {
  //   return <Spinner variant="primary" animation="border" />;
  // }

  return (
    <>
      <div className="py-2">
        <BreadCrumb />
      </div>
      <Card>
        <Card.Header className="border-bottom">
          <Row className="align-items-center">
            <Col xs={12} md={7} className=" align-items-center">
              <h5 className="fs-9 mb-0 text-nowrap py-2 py-xl-0 ">
                Categories
              </h5>
              <div className="d-flex gap-3 pt-1">
                {/* <OverlayTrigger
                  overlay={<Tooltip id="collapse-tooltip">Collapse</Tooltip>}
                > */}
                <img
                  src={CollapseIcon}
                  onClick={handleCollapseAll}
                  alt="Collapse"
                  title="Collapse"
                  style={{ width: "15px", cursor: "pointer" }}
                />
                {/* </OverlayTrigger> */}

                {/* <OverlayTrigger
                  overlay={<Tooltip id="expand-tooltip">Expand</Tooltip>}
                > */}
                <img
                  src={ExpandIcon}
                  onClick={handleExpandAll}
                  alt="Expand"
                  title="Expand"
                  style={{ width: "15px", cursor: "pointer" }}
                />
                {/* </OverlayTrigger> */}
              </div>
            </Col>

            <Col
              xs={12}
              md={5}
              className="d-flex justify-content-end align-items-center gap-2"
            >
              {/* <div className="d-flex gap-3">
                <OverlayTrigger
                  overlay={<Tooltip id="collapse-tooltip">Collapse</Tooltip>}
                >
                  <img
                    src={CollapseIcon}
                    onClick={handleCollapseAll}
                    alt="Collapse"
                    style={{ width: "24px", cursor: "pointer" }}
                  />
                </OverlayTrigger>

                <OverlayTrigger
                  overlay={<Tooltip id="expand-tooltip">Expand</Tooltip>}
                >
                  <img
                    src={ExpandIcon}
                    onClick={handleExpandAll}
                    alt="Expand"
                    style={{ width: "24px", cursor: "pointer" }}
                  />
                </OverlayTrigger>
              </div> */}

              {/* <InputGroup className="flex-grow-1 mx-2">
                <FontAwesomeIcon
                  icon="search"
                  className="position-absolute text-400 mt-1 search-box-icon"
                />
                <FormControl
                  type="text"
                  placeholder="Search Category"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  style={{ paddingLeft: "25px" }}
                />
              </InputGroup> */}

              <Form className="position-relative">
                <FontAwesomeIcon
                  icon="search"
                  className="position-absolute text-400 search-box-icon"
                  style={{
                    left: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                />
                <Form.Control
                  type="search"
                  placeholder="Search..."
                  style={{ paddingLeft: "30px" }}
                  aria-label="Search"
                  className="rounded-pill search-input"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </Form>

              {/* <InputGroup>
                <FormControl
                  id="inlineFormInputGroup"
                  type="text"
                  placeholder="Search Category"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <InputGroup.Text>
                  <FontAwesomeIcon
                    icon="search"
                    id="inlineFormInputGroup"
                    className=" text-400 search-box-icon"
                  />
                </InputGroup.Text>
              </InputGroup> */}

              <OverlayTrigger overlay={<Tooltip id="new-tooltip">New</Tooltip>}>
                <Button
                  variant="falcon-default"
                  size="sm"
                  className="d-flex align-items-center"
                  onClick={() => setModalOpen(true)}
                >
                  <FontAwesomeIcon icon="plus" className="me-2" />
                  <span className="d-none d-sm-inline">New</span>
                </Button>
              </OverlayTrigger>
            </Col>
          </Row>
        </Card.Header>
        <div className="bg-white p-3 rounded-sm">
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <Button variant="falcon-default" size="sm" className="fs-10">
                Loading...
              </Button>
            </div>
          ) : (
            <div className="tree-view">{renderTree(filterTree(data))}</div>
          )}
        </div>
      </Card>
      <AddCategoryModal
        isOpenModal={modalOpen}
        setIsOpenModal={setModalOpen}
        selectedNode={selectedNode}
        setSelectedNode={setSelectedNode}
      />
    </>
  );
};

export default CategoriesPage;
